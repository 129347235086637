import { combineReducers } from 'redux';
import localeReducer from './localeReducer';
import getprojectListReducer from './getProjectListReducer';
// import getProjectListReducerNew from './getProjectListReducerNew';
import createNewProjectReducer from './createNewProjectReducer';
import createNewCustomerReducer from './createCustomerReducer';
import getCustomersListReducer from './getCustomersListReducer';
import getUserCustomersReducer from './getUserCustomersReducer';
import getAllProductsReducer from './getAllProductsReducer';
import {
    deleteTagTemplateReducer,
    getTagTemplatesReducer,
    saveTagTemplateReducer,
    updateTagTemplateReducer
} from "./TagTemplatesReducer"
import { ConceptTemplatesReducer } from "./ConceptTemplatesReducer"
import { TagTemplateOpenDialouge } from './TagTemplatesDialougeReducer';
import setBuilderPermissionsReducer from './setBuilderPermissionsReducer';
import userProfileReducer from './userProfileReducer';
import environmentReducer from './environmentReducer';
import getProductCharacteristics from './getProductCharacteristicsReducer';
import getNewProductListReducer from './getNewProductListReducer';
import getCharacteristicsControllerReducer from './getCharacteristicsControllerReducer';
import getCommonModel from './getCommonModelReducer';
import getSelectedProductDetails from './getSelectedProductDetailsReducer';
import HelperControlerReducer from './HelpControllerReducer';
import selectionSummaryReducer from './selectionSummaryReducer';
import newSelectionSummaryReducer from './newSelectionSummaryReducer';
import notificationReducer from './notificationReducer';
import loaderReducer from './loaderReducer';
import progressBarReducer from './progressBarReducer';
import projectMasterDataReducer from './projectMasterDataReducer';
// import projectMasterDataETReducer from './projectMasterDataETReducer';
import dataManagementReducer from './dataManagementReducer';
import productsReducer from './products/productsReducer';
import breadcrumbsNavigationReducer from "./breadcrumbsNavigationReducer";
import {
    projectSummaryReducer,
    CoolingDemandReducer,
    SaveCabinetDataReducer,
    CABinetResultReducer,
    RefrigerationSupplyReducer,
    PriceDetailsReducer,
    GetProductResultIDReducer,
    UpgradeTagVersionReducer,
} from './ProjectSummaryReducer';
import heatRecoveryReducer from './heatRecoveryReducer';
import guiReducer from '../../components/AutoCadGui/redux/GuiReducer';

const rootReducer = combineReducers({
    api: environmentReducer,
    locale: localeReducer,
    getProjectList: getprojectListReducer,
    createNewProject: createNewProjectReducer,
    createNewCustomerReducer,
    getUserCustomers: getUserCustomersReducer,
    getCustomersList: getCustomersListReducer,
    getAllProductsReducer,
    getProductCharacteristics,
    getNewProductListReducer,
    getCharacteristicsControllerReducer,
    getCommonModel,
    getSelectedProductDetails,
    selectionSummary: selectionSummaryReducer,
    newSelectionSummaryReducer,
    getTagTemplates: getTagTemplatesReducer,
    saveTagTemplate: saveTagTemplateReducer,
    deleteTagTemplate: deleteTagTemplateReducer,
    updateTagTemplate: updateTagTemplateReducer,
    getConceptTemplates: ConceptTemplatesReducer,
    TagTemplateOpenDialouge: TagTemplateOpenDialouge,
    setBuilderPermissionsReducer: setBuilderPermissionsReducer,
    userProfile: userProfileReducer,
    assistance: HelperControlerReducer,
    notification: notificationReducer,
    loader: loaderReducer,
    progressBar: progressBarReducer,
    dataManagment: dataManagementReducer,
    products: productsReducer,
    projectSummary: projectSummaryReducer,
    projectMasterData: projectMasterDataReducer,
    // projectMasterDataET: projectMasterDataETReducer,
    breadcrumbNavigation: breadcrumbsNavigationReducer,
    CoolingDemand: CoolingDemandReducer,
    CABINETID: SaveCabinetDataReducer,
    CABinetResult: CABinetResultReducer,
    RefrigerationSupply: RefrigerationSupplyReducer,
    PriceDetailsReducer: PriceDetailsReducer,
    GetProductResultIDReducer: GetProductResultIDReducer,
    UpgradeTagVersionReducer: UpgradeTagVersionReducer,
    heatRecovery: heatRecoveryReducer,
    autoCadGUI: guiReducer
});

export default rootReducer;