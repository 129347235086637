import { ORDEREDLAYERS } from "../libraries/Constants2";

// const coordinates = {
//     CCR_Suction_MT: {
//         startX: 2.8539,
//         startY: 53.5559,
//         endX: 27.4129,
//         endY: 53.5559,
//         materialDetails: [
//             {
//                 "label": "Line Material",
//                 "name": "lineMaterial",
//                 "type": "select",
//                 "options": [
//                     {
//                         "label": "K65 inch",
//                         "value": "k65_inch"
//                     },
//                     {
//                         "label": "Copper mm",
//                         "value": "copper_mm"
//                     }
//                 ],
//                 "error": "",
//                 "defaultValue": "k65_inch",
//                 "value": "copper_mm",
//                 "selectedUnit": ""
//             },
//             {
//                 "label": "Maximum Pressure Drop",
//                 "name": "maxPressureDropAllowedInKelvin",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "k",
//                     "m/s"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 1.5,
//                 "selectedUnit": "k"
//             },
//             {
//                 "label": "Maximum Velocity",
//                 "name": "maxSpeedAllowedInMetersPerSecond",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "m/s",
//                     "k"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 20.0,
//                 "selectedUnit": "m/s"
//             }
//         ]
//     },
//     CCR_Liquid_LT_MT: {
//         startX: 2.8539,
//         startY: 53.7559,
//         endX: 27.2129,
//         endY: 53.7559,
//         materialDetails: [
//             {
//                 "label": "Line Material",
//                 "name": "lineMaterial",
//                 "type": "select",
//                 "options": [
//                     {
//                         "label": "K65 inch",
//                         "value": "k65_inch"
//                     },
//                     {
//                         "label": "Copper mm",
//                         "value": "copper_mm"
//                     }
//                 ],
//                 "error": "",
//                 "defaultValue": "k65_inch",
//                 "value": "copper_mm",
//                 "selectedUnit": ""
//             },
//             {
//                 "label": "Maximum Pressure Drop",
//                 "name": "maxPressureDropAllowedInKelvin",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "k",
//                     "m/s"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 1,
//                 "selectedUnit": "k"
//             },
//             {
//                 "label": "Maximum Velocity",
//                 "name": "maxSpeedAllowedInMetersPerSecond",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "m/s",
//                     "k"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 1.5,
//                 "selectedUnit": "m/s"
//             },
//             {
//                 "label": "Maximum Velocity before consumer",
//                 "name": "maxAllowedSpeedBeforeConsumerInMetersPerSecond",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "m/s",
//                     "k"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 20.0,
//                 "selectedUnit": "m/s"
//             }
//         ]
//     },
//     CCR_Condensate_Line: {
//         startX: '',
//         startY: '',
//         endX: '',
//         endY: ''
//     },
//     CCR_Discharge_Line: {
//         startX: '',
//         startY: '',
//         endX: '',
//         endY: ''
//     },
//     // Last one Pipe blue
//     CCR_Suction_LT: {
//         startX: 26.2459,
//         startY: 66.8584,
//         endX: 26.2459,
//         endY: 67.4664,
//         materialDetails: [
//             {
//                 "label": "Line Material",
//                 "name": "lineMaterial",
//                 "type": "select",
//                 "options": [
//                     {
//                         "label": "K65 inch",
//                         "value": "k65_inch"
//                     },
//                     {
//                         "label": "Copper mm",
//                         "value": "copper_mm"
//                     }
//                 ],
//                 "error": "",
//                 "defaultValue": "k65_inch",
//                 "value": "copper_mm",
//                 "selectedUnit": ""
//             },
//             {
//                 "label": "Maximum Pressure Drop",
//                 "name": "maxPressureDropAllowedInKelvin",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "k",
//                     "m/s"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 1,
//                 "selectedUnit": "k"
//             },
//             {
//                 "label": "Maximum Velocity",
//                 "name": "maxSpeedAllowedInMetersPerSecond",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "m/s",
//                     "k"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 1.5,
//                 "selectedUnit": "m/s"
//             },
//             {
//                 "label": "Maximum Velocity before consumer",
//                 "name": "maxAllowedSpeedBeforeConsumerInMetersPerSecond",
//                 "type": "input",
//                 "inputType": "number",
//                 "units": [
//                     "m/s",
//                     "k"
//                 ],
//                 "error": "",
//                 "defaultValue": "",
//                 "value": 20.0,
//                 "selectedUnit": "m/s"
//             }
//         ]
//     },
//     CCR_Hot_gas: {
//         startX: '',
//         startY: '',
//         endX: '',
//         endY: ''
//     }
// }

const initialState = {
    ...ORDEREDLAYERS.reduce((acc, key) => {
        acc[key.name] = {
            // startX: coordinates[key.name].startX,
            // startY: coordinates[key.name].startY,
            // endX: coordinates[key.name].endX,
            // endY: coordinates[key.name].endY,
            startX: null,
            startY: null,
            endX: null,
            endY: null,
            data: [],
            // materialDetails: coordinates[key.name].materialDetails
            materialDetails: []
        }
        return acc
    }, {}),
    cabinets: [],
    airCooler: [],
    gasCooler: [],
    groups: [],
    tags: [],
    uploadedData: [],
    loadedLayers: [],
    pipingsResult: null,
    pointingLines: [],
    existingPipeLayers: []
};

//Design 2
// const initialState2 = {
//     Carrier_Aspiratie_MT: {
//         startX: 179.5067,
//         startY: 6737.3756,
//         endX: 179.5067,
//         endY: 6757.2185,
//         data: []
//     },
//     Carrier_Lichid_MT_LT: {
//         startX: 179.6910,
//         startY: 6737.2185,
//         endX: 179.6910,
//         endY: 6757.2185,
//         data: []
//     },
//     Carrier_Aspiratie_LT: {
//         startX: 180.7081,
//         startY: 6757.8404,
//         endX: 185.9079,
//         endY: 6757.8404,
//         data: [],
//     },
//     Carrier_Gaze_Calde: {
//         startX: 180.7081,
//         startY: 6757.6408,
//         endX: 185.7081,
//         endY: 6757.6408,
//         data: [],
//     },
//     cabinets: [],
//     airCooler: [],
//     gasCooler: [],
//     groups: [],
//     tags: [],
//     racks: [],
//     uploadedData: [],
//     loadedLayers: [],
//     pipingsResult: null,
//     pointingLines: []
// };

const guiReducer = (state = initialState, action) => {
    const layer = action.layer;
    switch (action.type) {
        case 'ALL_LAYERS_DATA':
            return {
                ...state,
                uploadedData: action.payload
            };
        case 'PROCESS_PIPES_DATA':
            return {
                ...state,
                [layer]: { ...state[layer], data: action.payload.mtPipes }
            };
        case 'PROCESS_CABINETS_DATA':
            return {
                ...state,
                cabinets: action.payload
            };
        case 'PROCESS_GROUPS_DATA':
            return {
                ...state,
                groups: action.payload
            };
        case 'PROCESS_TAGS_DATA':
            return {
                ...state,
                tags: action.payload
            };
        case 'PROCESS_AIRCOOLER_DATA':
            return {
                ...state,
                airCooler: action.payload
            };
        case 'PROCESS_POINTING_LINES_DATA':
            return {
                ...state,
                pointingLines: action.payload
            };
        case 'SAVE_LOADED_LAYERS':
            return {
                ...state,
                loadedLayers: action.payload
            };
        case 'PROCESS_RACKS_DATA':
            return {
                ...state,
                racks: action.payload
            };
        case 'SAVE_START_AND_END_COORDINATES':
            const updatedState = { ...state };
            Object.keys(action.payload).forEach((key) => {
                if (updatedState[key]) {
                    updatedState[key] = {
                        ...updatedState[key],
                        ...action.payload[key]
                    }
                } else {
                    updatedState[key] = action.payload[key];
                }
            });
            return updatedState;

        case 'PIPING_CALCULATION_RESULT':
            return {
                ...state,
                pipingsResult: action.payload
            }
        case 'SAVE_EXISTING_PIPE_LAYERS':
            return {
                ...state,
                existingPipeLayers: action.payload
            }
        case 'SAVE_PIPE_NETWORK_EDIT':

            // const data = {
            //     ...state,
            //     ...Object.keys( action.payload ).reduce( ( acc, key ) => {
            //         acc[key] = {
            //             ...state[key],
            //             fields: action.payload[key].fields
            //         };
            //         return acc;
            //     },{})
            // }

            const updatedPipeState = { ...state };

            action.payload.forEach((data, index) => {
                const layerName = Object.keys(data);
                const key = layerName[0];
                if (updatedPipeState[key]) {
                    updatedPipeState[key] = {
                        ...updatedPipeState[key],
                        materialDetails: data[key]['fields']
                    }
                }
            })
            return updatedPipeState
        default:
            return state;
    }
};
export default guiReducer;