/** Purple Pipe */
export const LICHID_MT_LT = {
  name: "CCR_Liquid_LT_MT",
  label: "Liquid Line",
  excelName: "CCR_Liquid_LT_MT",
  payloadName: "liquidLine",
  resultName: "LiquidLine"
};

/** Red Pipe */
export const ASPIRATES_MT = {
  name: "CCR_Suction_MT",
  label: "Suction MT Line",
  excelName: "CCR_Suction_MT",
  resultName: "SuctionLine",
  payloadName: "suctionLine",
  flowDirection: 'reverse'
};

/** Blue Pipe */
export const ASPIRATES_LT = {
  name: "CCR_Condensate_Line",
  label: "Condensate Line",
  excelName: "CCR_Condensate_Line",
  resultName: "CondensateLine",
  payloadName: "condensateLine",
  flowDirection: 'reverse'
};
/** Orange Pipe */
export const GAZE_CALDE = {
  name: "CCR_Discharge_Line",
  label: "Discharge Line",
  excelName: "CCR_Discharge_Line",
  resultName: "DischargeLine",
  payloadName: "dischargeLine"
}

/** Blue Pipe */
export const SUCTIONS_LT = {
  name: "CCR_Suction_LT",
  excelName: "CCR_Suction_LT",
  label: 'Suction LT Line',
  payloadName: 'suctionLine',
  resultName: 'SuctionLine'
}

export const HOT_GAS = {
  name: "CCR_Hot_gas",
  excelName: "CCR_Hot gas",
  label: 'Hot gas Line',
  payloadName: 'hotGasLine',
  resultName: 'HotGasLine'
}

export const ORDEREDLAYERS = [
  LICHID_MT_LT,
  ASPIRATES_MT,
  ASPIRATES_LT,
  GAZE_CALDE,
  SUCTIONS_LT,
  HOT_GAS
]

export const CARRIER_MT = "Carrier_MT"
export const DASHED = "( Dashed )";
export const LT_CABINETS = "CCR_cabinets";
export const MT_GROUPS = "CCR_groups";
export const MT_AIRCOOLER = "CCR_aircooler";
export const MT_TAGS = "CCR_tags";
export const CARRIER_LT = "CARRIER_LT";

export const POINTING_LINES_LAYER="CCR_pointing_lines";

export const CARRIER_SCURGERI = "Carrier_scurgeri";
export const CARRIER_GASCOOLER_MODEL = "Carrier_GasCooler_model";
export const CARRIER_GASCOOLER_NAME = "Carrier_GasCooler_name";
export const CARRIER_RACKS_DESC = "Carrier_Racks_desc";
export const CARRIER_RACKS_NAME = "CCR_racks";

export const CARRIER_ECHIIPAMENTE = "Carrier Echipamente"

export const LAYERS_REQUIRED = [
  {
    name: LICHID_MT_LT.excelName,
    isExist: false
  },
  {
    name: ASPIRATES_MT.excelName,
    isExist: false
  },
  {
    name: ASPIRATES_LT.excelName,
    isExist: false
  },
  {
    name: GAZE_CALDE.excelName,
    isExist: false
  },
  {
    name: SUCTIONS_LT.excelName,
    isExist: false
  },
  {
    name: CARRIER_MT,
    isExist: false
  },
  {
    name: LT_CABINETS,
    isExist: false
  },
  {
    name: MT_GROUPS,
    isExist: false
  },
  {
    name: MT_AIRCOOLER,
    isExist: false
  },

  {
    name: MT_TAGS,
    isExist: false
  },
  {
    name: CARRIER_SCURGERI,
    isExist: false
  },
  {
    name: POINTING_LINES_LAYER,
    isExist: false
  },
  {
    name: CARRIER_GASCOOLER_MODEL,
    isExist: false
  },
  {
    name: CARRIER_GASCOOLER_NAME,
    isExist: false
  },
  {
    name: CARRIER_RACKS_NAME,
    isExist: false
  },
  {
    name: CARRIER_RACKS_DESC,
    isExist: false
  },
  {
    name: CARRIER_ECHIIPAMENTE,
    isExist: false
  }
];


export const payloadData_old = {
  "gasCoolerSupply": {
    "systemName": "Simple CSS test system",
    "gasCooler": {
      "nodeNumber": 3,
      "outletTemperatureSummerInCelsius": 36.0,
      "outletTemperatureWinterInCelsius": 10.0
    },
    "liquidReceiverNodeNumber": 0,
    "transcriticalHighPressureCurveId": 2,
    "useActiveOilManagement": true,
    "oilInRefrigerantInPercent": 1.5,
    "additionalSubcoolingForLiquidColumnInKelvin": 0,
    "temperatureInLiquidReceiverInCelsius": 1,
    "dischargeLine": {
      "maxPressureDropAllowedInKelvin": 1,
      "maxSpeedAllowedInMetersPerSecond": 15,
      "pipeMaterial": {
        "pipeMaterialId": 25,
        "maxNotSplitDiameterId": 884,
        "selectedDiametersFromMaterialSet": [
          395,
          396,
          397,
          473,
          504,
          804,
          829,
          830,
          831,
          884
        ]
      },
      "pipeSections": [
      ]
    },
    "condensateLine": {
      "maxPressureDropAllowedInKelvin": 1,
      "maxSpeedAllowedInMetersPerSecond": 0.6,
      "pipeMaterial": {
        "pipeMaterialId": 25,
        "maxNotSplitDiameterId": 884,
        "selectedDiametersFromMaterialSet": [
          395,
          396,
          397,
          473,
          504,
          804,
          829,
          830,
          831,
          884
        ]
      },
      "pipeSections": [

      ]
    },
    "liquidLine": {
      "maxPressureDropAllowedInKelvin": 1,
      "maxSpeedAllowedInMetersPerSecond": 1.5,
      "maxAllowedSpeedBeforeConsumerInMetersPerSecond": 1.5,
      "pipeMaterial": {
        "pipeMaterialId": 1,
        "maxNotSplitDiameterId": 11,
        "selectedDiametersFromMaterialSet": [
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11
        ]
      },
      "pipeSections": [

      ]
    }
  },
  "consumerSubsystems": [
    {
      "systemName": "MT system",
      "winterLoadInPercent": 100,
      "compressor": {
        "nodeNumber": 2,
        "compressorCapacityInKw": 26.67,
        "condenserCapacityInKw": 37.34,
        "compressorQuantity": 1,
        "simultaneousFactor": 1,
        "suctionGasTemperature": 20,
        "suctionGasTemperatureUnit": "Kelvin",
        "superheatAtCompressorSetInKelvin": 20,
        "usefulSuperheatInKelvin": 7
      },
      "coolingPositions": [],
      "suctionLine": {
        "maxPressureDropAllowedInKelvin": 1.5,
        "maxSpeedAllowedInMetersPerSecond": 20.0,
        "pipeMaterial": {
          "pipeMaterialId": 1,
          "maxNotSplitDiameterId": 11,
          "selectedDiametersFromMaterialSet": [
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11
          ]
        },
        "pipeSections": [
        ]
      }
    }
  ]
}

export const payloadData = {
  "gasCoolerSupply": {
    "systemName": "Simple Aldi project",
    "gasCooler": {
      "nodeNumber": 200,
      "outletTemperatureSummerInCelsius": 36.0,
      "outletTemperatureWinterInCelsius": 10.0
    },
    "liquidReceiverNodeNumber": 300,
    "transcriticalHighPressureCurveId": 1,
    "useActiveOilManagement": true,
    "oilInRefrigerantInPercent": 1.5,
    "additionalSubcoolingForLiquidColumnInKelvin": 0,
    "temperatureInLiquidReceiverInCelsius": 1,
    "liquidLine": {
      "maxPressureDropAllowedInKelvin": 1,
      "maxSpeedAllowedInMetersPerSecond": 1.5,
      "maxAllowedSpeedBeforeConsumerInMetersPerSecond": 0.8,
      "pipeMaterial": "copper_mm",
      "pipeSections": [
        {
          "fromNode": 20,
          "toNode": 10,
          "lengthInMeter": 5.47
        },
        {
          "fromNode": 20,
          "toNode": 8,
          "lengthInMeter": 0.87
        },
        {
          "fromNode": 999,
          "toNode": 20,
          "lengthInMeter": 2.26
        },
        {
          "fromNode": 999,
          "toNode": 9,
          "lengthInMeter": 0.87
        },
        {
          "fromNode": 21,
          "toNode": 999,
          "lengthInMeter": 8.63
        },
        {
          "fromNode": 22,
          "toNode": 1,
          "lengthInMeter": 3.76
        },
        {
          "fromNode": 22,
          "toNode": 2,
          "lengthInMeter": 0.33
        },
        {
          "fromNode": 23,
          "toNode": 22,
          "lengthInMeter": 3.76
        },
        {
          "fromNode": 23,
          "toNode": 3,
          "lengthInMeter": 0.33
        },
        {
          "fromNode": 24,
          "toNode": 23,
          "lengthInMeter": 2.58
        },
        {
          "fromNode": 25,
          "toNode": 7,
          "lengthInMeter": 6.26
        },
        {
          "fromNode": 25,
          "toNode": 6,
          "lengthInMeter": 0.33
        },
        {
          "fromNode": 26,
          "toNode": 25,
          "lengthInMeter": 3.75
        },
        {
          "fromNode": 26,
          "toNode": 5,
          "lengthInMeter": 0.33
        },
        {
          "fromNode": 27,
          "toNode": 26,
          "lengthInMeter": 3.75
        },
        {
          "fromNode": 27,
          "toNode": 4,
          "lengthInMeter": 0.33
        },
        {
          "fromNode": 24,
          "toNode": 27,
          "lengthInMeter": 1.77
        },
        {
          "fromNode": 28,
          "toNode": 24,
          "lengthInMeter": 1.56
        },
        {
          "fromNode": 29,
          "toNode": 28,
          "lengthInMeter": 5.00,
          "direction": "Down"
        },
        {
          "fromNode": 30,
          "toNode": 29,
          "lengthInMeter": 1.00
        },
        {
          "fromNode": 21,
          "toNode": 30,
          "lengthInMeter": 1.00
        },
        {
          "fromNode": 300,
          "toNode": 21,
          "lengthInMeter": 23.70
        }
      ]
    }
  },
  "consumerSubsystems": [
    {
      "systemName": "MT system",
      "compressor": {
        "nodeNumber": 100,
        "compressorCapacityInKw": 54.09,
        "condenserCapacityInKw": 75.73,
        "simultaneousFactor": 1,
        "suctionGasTemperature": 20,
        "suctionGasTemperatureUnit": "Kelvin",
        "superheatAtCompressorSetInKelvin": 12,
        "usefulSuperheatInKelvin": 6
      },
      "coolingPositions": [
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "01",
          "nodeNumber": 1,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -5.00,
          "q0At2560InWatt": 4980,
          "pipeVolumeInLiter": 4.86
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "02",
          "nodeNumber": 2,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -5.00,
          "q0At2560InWatt": 7480,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "03",
          "nodeNumber": 3,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -2.00,
          "q0At2560InWatt": 6350,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "04",
          "nodeNumber": 4,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -2.00,
          "q0At2560InWatt": 6350,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "05",
          "nodeNumber": 5,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -2.00,
          "q0At2560InWatt": 6350,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "06",
          "nodeNumber": 6,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -2.00,
          "q0At2560InWatt": 6040,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "MARESS 82.M2-1 DL",
          "position": "07",
          "nodeNumber": 7,
          "consumerType": "Cabinet",
          "t0At2560InCelsius": -2.00,
          "q0At2560InWatt": 6040,
          "pipeVolumeInLiter": 8.25
        },
        {
          "description": "Dairy",
          "position": "08",
          "nodeNumber": 8,
          "consumerType": "ColdRoom",
          "t0At2560InCelsius": -3.00,
          "q0At2560InWatt": 3500
        },
        {
          "description": "Meat ",
          "position": "09",
          "nodeNumber": 9,
          "consumerType": "ColdRoom",
          "t0At2560InCelsius": -7.00,
          "q0At2560InWatt": 2000
        }
      ],
      "suctionLine": {
        "maxPressureDropAllowedInKelvin": 1.5,
        "maxSpeedAllowedInMetersPerSecond": 20.0,
        "pipeMaterial": "copper_mm",
        "pipeSections": [
          {
            "fromNode": 300,
            "toNode": 20,
            "lengthInMeter": 5.47
          },
          {
            "fromNode": 8,
            "toNode": 20,
            "lengthInMeter": 0.87
          },
          {
            "fromNode": 20,
            "toNode": 999,
            "lengthInMeter": 2.26
          },
          {
            "fromNode": 9,
            "toNode": 999,
            "lengthInMeter": 0.87
          },
          {
            "fromNode": 999,
            "toNode": 21,
            "lengthInMeter": 8.63
          },
          {
            "fromNode": 1,
            "toNode": 22,
            "lengthInMeter": 3.76
          },
          {
            "fromNode": 2,
            "toNode": 22,
            "lengthInMeter": 0.33
          },
          {
            "fromNode": 22,
            "toNode": 23,
            "lengthInMeter": 3.76
          },
          {
            "fromNode": 3,
            "toNode": 23,
            "lengthInMeter": 0.33
          },
          {
            "fromNode": 23,
            "toNode": 24,
            "lengthInMeter": 2.58
          },
          {
            "fromNode": 7,
            "toNode": 25,
            "lengthInMeter": 6.26
          },
          {
            "fromNode": 6,
            "toNode": 25,
            "lengthInMeter": 0.33
          },
          {
            "fromNode": 25,
            "toNode": 26,
            "lengthInMeter": 3.75
          },
          {
            "fromNode": 5,
            "toNode": 26,
            "lengthInMeter": 0.33
          },
          {
            "fromNode": 26,
            "toNode": 27,
            "lengthInMeter": 3.75
          },
          {
            "fromNode": 4,
            "toNode": 27,
            "lengthInMeter": 0.33
          },
          {
            "fromNode": 27,
            "toNode": 24,
            "lengthInMeter": 1.77
          },
          {
            "fromNode": 24,
            "toNode": 28,
            "lengthInMeter": 1.56
          },
          {
            "fromNode": 28,
            "toNode": 29,
            "lengthInMeter": 5.00,
            "direction": "Up"
          },
          {
            "fromNode": 29,
            "toNode": 30,
            "lengthInMeter": 1.00
          },
          {
            "fromNode": 30,
            "toNode": 21,
            "lengthInMeter": 1.00
          },
          {
            "fromNode": 21,
            "toNode": 100,
            "lengthInMeter": 23.70
          }
        ]
      },
      "boosterSubsystems": [
        {
          "systemName": "LT system",
          "boosterOutletNodeNumber": 300,
          "compressor": {
            "nodeNumber": 101,
            "compressorCapacityInKw": 4,
            "condenserCapacityInKw": 5,
            "simultaneousFactor": 1,
            "suctionGasTemperature": 20,
            "suctionGasTemperatureUnit": "Kelvin",
            "superheatAtCompressorSetInKelvin": 17,
            "usefulSuperheatInKelvin": 6
          },
          "coolingPositions": [
            {
              "description": "Freezer room",
              "position": "10",
              "nodeNumber": 10,
              "consumerType": "ColdRoom",
              "t0At2560InCelsius": -27,
              "q0At2560InWatt": 4000
            }
          ],
          "suctionLine": {
            "maxPressureDropAllowedInKelvin": 1.5,
            "maxSpeedAllowedInMetersPerSecond": 20.0,
            "pipeMaterial": "copper_mm",
            "pipeSections": [
              {
                "fromNode": 10,
                "toNode": 101,
                "lengthInMeter": 3.42
              }
            ]
          }
        }
      ]
    }
  ]
}